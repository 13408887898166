import $ from 'jquery';
import 'what-input';
import libs from './lib/dependencies';


window.$ = $;
window.jQuery = $;
window.libs = libs;


//npm Modules
//require('animejs');
import anime from 'animejs';
import ScrollReveal from 'scrollreveal';

//Pixmill Scripts
require('../../../foundation/assets/js/px/hamburger.js');
//require('../../../foundation/assets/js/px/opaque.js');

$(document).ready(function() {


  //Effekt für Header Bereich
  $(document).on('scroll', function() {

      if($(this).scrollTop() > 120) {
        setTimeout(function(){
          $('.h-fixed-top').addClass('opaque');
          anime({
            targets: 'path.header',
            d: [
              { value: 'M1626,325.9c-128.2,0-253.1,0-419,0c-421.6,0-955.7,0-1207,0V0h1626V325.9z' }
            ],
            easing: 'easeOutQuad',
            duration: 200,
            loop: false
          });
          anime({
            targets: 'svg#header-bg',
            viewBox: [
              { value: '0 0 1626 340' }
            ],
            easing: 'easeOutQuad',
            duration: 200,
            loop: false
          });
        }, 100);
      } else {
        setTimeout(function(){
          $('.h-fixed-top').removeClass('opaque');
          anime({
            targets: 'path.header',
            d: [
              { value: 'M1626,570c-129.7-22.9-262-67.6-419-147.6c-415.5-211.7-953.7-93-1207-16.5V0h1626V570z' }
            ],
            easing: 'easeOutQuad',
            duration: 200,
            loop: false
          });
          anime({
            targets: 'svg#header-bg',
            viewBox: [
              { value: '0 0 1626 570' }
            ],
            easing: 'easeOutQuad',
            duration: 200,
            loop: false
          });
        }, 100);
      }

  });

  //scrollreveal
  ScrollReveal().reveal('.fade-in-interval, .header-txt .lead', {
    origin: 'bottom',
    distance: '30px',
    easing: 'cubic-bezier(.6,.26,.35,.74)',
    duration: 700,
    interval: 300
  });
  ScrollReveal().reveal('.workshop-box, .teacher-ov-box, .promo-box', {
    origin: 'bottom',
    distance: '20px',
    easing: 'cubic-bezier(.6,.26,.35,.74)',
    duration: 700,
    interval: 300
  });
  ScrollReveal().reveal('.fade-in-bottom', {
    origin: 'bottom',
    distance: '30px',
    easing: 'cubic-bezier(.6,.26,.35,.74)',
    duration: 700
  });

  //Animation Dropdown Navi
  $('#main-menu ul').on(
      'show.zf.dropdownMenu', function() {
        var dropdown = $(this).find('.is-active .is-dropdown-submenu');
        dropdown.css('display', 'none');
        dropdown.fadeIn('slow');
    });
    $('#main-menu ul').on(
      'hide.zf.dropdownMenu', function() {
        var dropdown = $(this).find('.is-active .is-dropdown-submenu');
        dropdown.css('display', 'inherit');
        dropdown.fadeOut('slow');
    });


    //Fito-reveal
    $('.fito-reveal').click(function() {
      $(this).next('.fito-reveal-bubble').toggleClass('clicked');
      var iframe_url = $(this).next().find('iframe').attr('data-src');
      $('iframe#embeddedFrame').attr('src', iframe_url);
      return false;
    });
    $('.fito-reveal-close-trigger').click(function() {
      $('.fito-reveal-bubble').removeClass('clicked');
      return false;
    });

    //Fito mit Accordion
    $('[data-accordion]').on('down.zf.accordion', function () {
      var iframe_url = $(this).find('iframe').attr('data-src');
      $('iframe#embeddedFrame').attr('src', iframe_url);
    });



  $('#search-reveal').on('open.zf.reveal', function() {
    $(this).find("input#search").focus();
  });


  //Ajaxform Schnupperkurs
  $(document).on('af_complete', function(event, response) {
  var form = response.form;
  var resp = response.success;
  //console.log(resp);
  // ID des Formulars
  if (form.attr('id') == 'kontaktform' && resp === true) {
     var successMessage = $('.alert.alert-success').html();

     $("form").replaceWith( '<div class="grid-container"> <div class="grid-x grid-padding-x"> <div class="medium-12 cell"> <h2>' + successMessage + '</h2> </div> </div> </div>' );
     $("#contact-form").css('top','150px');
     $("button.close-button").delay( 2000 ).fadeOut(1000,function(){
       $(this).click();
     });
     //$("button.close-button").click();

     }
    // else: Error in Console
    else {
       //console.log(response)
    }
  });






});

    //Ajaxform Schnupperkurs
    $(document).on('af_complete', function(event, response) {
    var form = response.form;
    var resp = response.success;
    //console.log(resp);
    // ID des Formulars
    if (form.attr('id') == 'kontaktform' && resp === true) {
       var successMessage = $('.alert.alert-success').html();

       $("form").replaceWith( '<div class="grid-container"> <div class="grid-x grid-padding-x"> <div class="medium-12 cell"> <h2>' + successMessage + '</h2> </div> </div> </div>' );
       $("#contact-form").css('top','150px');
       $("button.close-button").delay( 2000 ).fadeOut(1000,function(){
         $(this).click();
       });
       //$("button.close-button").click();

       }
    // else: Error in Console
    else {
       //console.log(response)
    }
    });

//import Foundation from 'foundation-sites';
// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//require('foundation-sites');
import './lib/foundation-explicit-pieces';

$(document).foundation();
